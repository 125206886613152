import clsx from "clsx"
import styles from "./loader.module.css"
import { useEffect, useRef, useState } from "react"


export default function Loader({ className,isCalc }) {
    const [marginTop, setMarginTop] = useState();
    const ref = useRef();


    useEffect(() => {
        if (ref.current && isCalc) {
            if (ref.current.offsetHeight > window.innerHeight) {
                setMarginTop(( window.innerHeight/2) - 20)
            }
        }
    }, [ref.current]);

    return (
        <div ref={ref} className={clsx(styles["box"], className,marginTop? "align-items-start":"align-items-center", "position-absolute d-flex align-items-start justify-content-center w-100 h-100 inset-0")}>
            <svg style={{ marginTop }} className={clsx(styles["svg"],"d-block")} width="80px" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 100 100" enable-background="new 0 0 0 0" >
                <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                </path>
            </svg>
        </div>
    )
}
