export default function useQuery() {
    const url = window.location.href;

    const urlParams = new URL(url);

    const queryParams = new URLSearchParams(urlParams.search);

    // Create an object to store the final query parameters
    const finalObject = {};

    queryParams.forEach((value, key) => {
        finalObject[key] = value;
    });

    return finalObject;
}
