import { useLayoutEffect, useRef, useState } from "react";
import "./index.css";
import { clientTypes, entityTypes, eventTypes } from "./configs/variables";
import WrapperContainer from "./Components/WrapperContainer";
import dynamic from "./helpers/dynamic";
import useQuery from "./hooks/useQuery";

const LoanBre = dynamic(() => import("./Components/LoanBre"));
const BillPay = dynamic(() => import("./Components/BillPay"));
const LoanBreProvider = dynamic(() => import("./context/LoanBre"));

/**
 *
 * @eventData contains = {
 *      entity:entityTypes[entityType],
 *      clientType:clientTypes[clientType],
 *      authToken,
 *      pincode
 * }
 */
function App() {
  const [eventData, setEventData] = useState(null);
  const eventSource = useRef(null);
  const queryParams = useQuery();

  const postMessage = (data) => {
    eventSource.current.source.postMessage(data, "*");
  };

  const onClosedByUser = () => {
    if (queryParams?.redirect_url || eventData?.redirect_url) {
      window.open(queryParams.redirect_url || eventData.redirect_url);
    }
    if (!eventData.isSelf) {
      postMessage({ event: eventTypes.sdk_closed });
    }

    setEventData(null);
  };

  const handleMessageEvent = (event) => {
    //Need to add a condition to check that event is tiggered by whitelisted origin or not.
    if (event.data.event == eventTypes.sdk_invoked) {
      const { authToken, clientType, ...restEventData } = event.data.data;
      setEventData(restEventData);
      const getToken = (isTrue) => (isTrue ? authToken : null);
      eventSource.current = {
        source: event.source,
        origin: event.origin,
        clientType,
        dsaToken: getToken(clientType == clientTypes.b2b_web),
        customerToken: getToken(clientType == clientTypes.b2c_web),
      };
      if (!event.data.data.isSelf) {
        eventSource.current.source.postMessage(
          { event: eventTypes.sdk_initiated },
          "*"
        );
      }
    }
  };

  window.onLogout = () => setTimeout(onClosedByUser, 1500);

  //listen the message requests
  useLayoutEffect(() => {
    window.addEventListener("message", handleMessageEvent);
  }, []);
  //This event will help to open sdk based on the recived parameters
  useLayoutEffect(() => {
    if (clientTypes[queryParams.client_type] == clientTypes.b2c_app) {
      handleMessageEvent(
        {
          data: {
            event: eventTypes.sdk_invoked,
            data: {
              entity: entityTypes[queryParams.loan_type],
              clientType: clientTypes[queryParams.client_type],
              authToken: queryParams.auth_token,
              pincode: queryParams.pincode,
              loanVendor: {
                id: queryParams.product_id,
                product_id: queryParams.sub_product_id,
                product_vendor: queryParams.product_vendor,
              },
              stageType: queryParams.stage_type,
              applicationId: queryParams.application_id,
              isSelf: true,
              redirect_url: queryParams.redirect_url,
            },
          },
        },
        true
      );
    }
  }, []);

  if (eventData) {
    const clientType = eventSource.current.clientType;

    if (eventData.entity.type == entityTypes.credit_score.type) {
      return <WrapperContainer />;
    }
    if (eventData.entity.type == entityTypes.bill_payment.type) {
      return (
        <WrapperContainer>
          <BillPay
            bannerImg={entityTypes.bill_payment.bannerImg}
            onClosedByUser={onClosedByUser}
            customerToken={eventSource.current.customerToken}
            dsaToken={eventSource.current.dsaToken}
          />
        </WrapperContainer>
      );
    }

    return (
      <WrapperContainer>
        <LoanBreProvider>
          <LoanBre
            clientType={clientType}
            bannerImg={eventData.entity.imgSrc}
            defaultPincode={eventData.pincode}
            applicationId={eventData.applicationId}
            stageType={eventData.stageType}
            onClosedByUser={onClosedByUser}
            loanType={eventData.entity.type}
            loanVendor={eventData.loanVendor}
            isDisabledBreFlow={
              entityTypes[eventData.entity.type].disabledBreFlow
            }
            customerToken={eventSource.current.customerToken}
            dsaToken={eventSource.current.dsaToken}
            hasRedirect={
              Boolean(queryParams?.redirect_url) ||
              Boolean(eventData.redirect_url)
            }
          />
        </LoanBreProvider>
      </WrapperContainer>
    );
  }

  return <WrapperContainer />;
}

export default App;
