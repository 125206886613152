
export default function WrapperContainer({ children }) {
    return (
        <div className="wrapper-container overflow-auto">
            <div className="container  py-4 d-flex align-items-center" style={{ minHeight: "100vh" }}>
                {children}
            </div>
        </div>
    )
}
