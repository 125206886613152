import React from 'react';
import ReactDOM from 'react-dom/client';
import { createPortal } from 'react-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(createPortal(
    <>
        <ToastContainer style={{ zIndex: "9999999" }} />
        <App />
    </>
    , document.body));