import React, { Suspense } from 'react';
import Loader from '../Components/Loader';


const dynamic = (importFunc, { fallback = null } = {}) => {
  const LazyComponent = React.lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback || <Loader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default dynamic;
